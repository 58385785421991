import React from 'react'
import './support.scss'
import { Parallax } from 'react-scroll-parallax'

const Support = () => {
    return (
        <section className="support">
            {/* <img src="\assets\last-bg.svg" alt="supportbg" className="supportbg" /> */}
            <div className="circlemain">
            <span className="circleone"></span>
            <span className="circletwo"></span>
            <span className="circlethree"></span>
            </div>
            <div className="custom-container">
                <div className="supportmain">
                    {/* <p className="supporttoppara">
                        value is a shared vision
                    </p> */}
                     <Parallax scale={[0.6, 1]} style={{ height: "100%" }} >
                    <h4 className="supporthead">
                         <span className="boldtext">IF $NOTHING TURNS INTO $Something, <br /> Chapter 2 will begin...</span>
                    </h4>
                    </Parallax>
                    {/* <p className="supportlastpara">
                        $nothing is not just a coin, it’s a testament to the power of community. be a part of social experiment where we define the worth together
                    </p>
                    <div className="supportbtns">
                        <button className="whitebtn">
                            BUY ON UNISWAP
                        </button>
                        <button className="greybtn">
                            READ WHITEPAPER
                        </button>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default Support