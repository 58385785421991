
import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";

const Banner = ({ handleButtonClick }) => {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (linkIndex) => {
    setActiveLink((prevActiveLink) => (prevActiveLink === linkIndex ? null : linkIndex));
  };

  const handleBodyClick = (event) => {

    if (!event.target.closest('a')) {
      setActiveLink(null);
    }
  };

  useEffect(() => {

    document.body.addEventListener('click', handleBodyClick);


    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);
  return (
    <>
      <section className="main-banner">
        {/* <img src="\assets\banner-bg.svg" alt="img" className="img-fluid banner-bg" />
        <img src="\assets\banner-bg-mbl.svg" alt="img" className="img-fluid banner-bg-mbl d-none" /> */}
        {/* <span className="bg-layer"></span> */}
        {/* <video
          id="myVideo"
          className="video-player"
          muted="muted"
          playsinline="playsinline"
          autoPlay
          loop
          class="banner-bg-video"
        >
          <source
            src="https://res.cloudinary.com/drt6vurtt/video/upload/v1702301309/nothing/Nothing_Coin_Top_Grayscale_mhchbb.mp4"
            type="video/mp4"
          />
        </video> */}
        <div className="bannermoonmain">
          <img src="\assets\bannermoon.png" alt="img" className="bannercircle img-fluid" />
          <img src="\assets\bannerline.png" alt="img" className="bannercircleline img-fluid" />
        </div>
        <div style={{ cursor: "pointer" }} className="btn-more" onClick={handleButtonClick}>
          <h6>more</h6>
          <div class="arrow"></div>
        </div>
        <div className="custom-container">
          <div className="top-logo">
            <img src="\assets\nothingtoken.svg" alt="img" className="img-fluid" />
          </div>
          <div className="main-content">
            <h5>Welcome to $NOTHING</h5>
            <h2>a social experiment <br /> <span>in value creation</span></h2>
            <h6>turning $nothing into $<span style={{ color: "#FF3232" }}>s</span><span style={{ color: "#0286FF" }}>o</span><span style={{ color: "#64FF3D" }}>m</span><span style={{ color: "#FF0083" }}>e</span><span style={{ color: "#FFD600" }}>t</span><span style={{ color: "#AD00FF" }}>h</span><span style={{ color: "#FF7A00" }}>i</span><span style={{ color: "#00C2FF" }}>n</span><span style={{ color: "#53FFEA" }}>g</span></h6>
            <div className="bottom-btns">
              <div className="set-custom-parent">
                <a href="https://bit.ly/3RsGOup" target="_blank" className="btn-buy">BUY ON UNISWAP</a>
                {activeLink == 0 && <div className='nothing-text-banner formobilesetpositionbanner'>The Uniswap pool, AKA Pool of Dreams <br /> IS COMING VERY SOON...</div>}
              </div>
              <div className="set-custom-parent">
                <a href="\whitepaper.pdf" target="_blank" className="btn-whitepaper">READ WHITEPAPER</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
