import '../../App.scss';
import React, { useState, useEffect, useRef } from "react";
import Banner from './main-banner/Banner.js';
import Journey from './Journey/Journey.js';
import Magic from './Magic/Magic.js';
import Impact from './Impact/Impact.js';
import Story from './Story/Story.js';
import Essence from './Essence/Essence.js';
import Creator from './Creator/Creator.js';
import Collective from './Collective/Collective.js';
import Belief from './Belief/Belief.js';
import Consensus from './Consensus/Consensus.js';
import Support from './Support/Support.js';
import Footer from './footer/Footer.js';



function Landing() {
  useEffect(() => {
    document.title = "$NOTHING | A Social Experiment In Value Creation"
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sectionRef = useRef(null);

  const handleButtonClick = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <>

      <section style={{ height: '100vh' }} className='mobileeightauto'>
        <Banner handleButtonClick={handleButtonClick} />
      </section>

      {/* Second Section */}
      <section
        ref={sectionRef}
        style={{ height: '100vh'}}
        className='mobileeightauto'
      >
         <Magic />
      </section>

      
      <Journey />
      <Collective />
      <Impact />
      <Story />
      <Belief />
      <Essence />
      <Consensus />
      <Creator />
      <Support />
      <Footer />
    </>
  );
}

export default Landing;