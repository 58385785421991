import React from 'react'
import "./creator.scss"
import { Parallax } from 'react-scroll-parallax'

const Creator = () => {
  return (
    <>
      <section className="creator">
        <div className="custom-container">
            <div className="row">
                <div className="col-xl-6 col-12 p-0 m-auto">
                <Parallax speed={15} style={{ height: "100%" }} >
                    <div className="main-content">
                        <h6>⁠The Creator's <span>Pledge</span></h6>
                        <p>I STAND WITH YOU, NOT ABOVE YOU. AS THE CREATOR, I WILL NOT HOLD A SINGLE $NOTHING TOKEN. MY COMMITMENT IS TO THE EXPERIMENT AND ITS INTEGRITY, ENSURING A TRULY EQUITABLE START FOR ALL INVOLVED. AS THE CREATOR OF $NOTHING, MY VISION TRANSCENDS MONETARY GAIN. THIS ISN'T A PROJECT TO AMASS WEALTH for founders; IT'S A VENTURE INTO THE UNKNOWN REALMS OF VALUE CREATION.</p>
                        <p style={{fontWeight: "700"}}>I'M NOT HERE TO MAKE MONEY; I'M HERE TO MAKE A STATEMENT.</p>
                        <p style={{marginBottom: "10px"}}>Yours truly,</p>
                        <p style={{fontWeight: "700"}}>NO ONE</p>

                    </div>
                    </Parallax>
                </div>
                <div className="col-xl-6 col-12 p-0">
                    <div className="img-wrapper">
                        <img src="\assets\pledge.svg" alt="img" className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Creator
