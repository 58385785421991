import React from 'react'
import "./journey.scss"
import { Parallax } from 'react-scroll-parallax'

const Journey = () => {
    return (
        <>
            <section className="journey">
                <img src="\assets\journey-mbl.svg" alt="img" className='img-fluid journey-mbl d-none' />
                <div className="custom-container">
                    <div className="row">
                        <div className="col-xl-6 col-12 p-0">
                            <div className="img-wrapper">
                                <img src="\assets\journey.svg" alt="img" className='img-fluid set-padding-custom' />
                            </div>
                        </div>
                        <div className="col-xl-6 col-12 p-0 m-auto">
                            <Parallax speed={15} style={{ height: "100%" }} >
                                <div className="main-content">
                                    <img src="\assets\journeytextshadow.svg" alt="img" className='img-fluid text-abs' />
                                    <h6> a Journey from <br /> <span>zero to her0</span></h6>
                                    <span className='line-draw'></span>
                                    <p>$NOTHING WILL START AT $0.00008 AND WILL BECOME $SOMETHING WHEN IT REACHES $1. THIS MILESTONE IS A SYMBOL OF OUR COLLECTIVE BELIEF.</p>
                                </div>
                            </Parallax>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Journey
