import React from 'react'
import "./essence.scss"
import { Parallax } from 'react-scroll-parallax'

const Essence = () => {
  return (
    <>
      <section className="essence">
        <img src="\assets\fairlaunch.svg" alt="img" className='img-fluid fairlaunch' />
        <img src="\assets\fairlaunch-mbl.svg" alt="img" className='img-fluid fairlaunch-mbl d-none' />
        <div className="custom-container">
            <div className="main-heading">
                <h6>The Essence of a <br /> <span>100% Fair Launch</span></h6>
                <span className='line-draw'></span>
            </div>
            <div className="bottom-content">
            <Parallax speed={10} style={{ height: "100%" }} >
                <div className="single">
                    <h6>An Experiment in Value Creation:</h6>
                    <p>$NOTHING is more than a token; it's a demonstration of how belief & consensus build value. As a social experiment, it is designed to educate & enlighten on the dynamics of value creation.</p>
                </div>
                </Parallax>
                <Parallax speed={20} style={{ height: "100%" }} >
                <div className="single">
                    <h6>True Fairness:</h6>
                    <p>In an UNCONVENTIOAL move in the crypto world, we are launching $NOTHING with a 100% fair launch. Every single one of the 311,020,080 tokens will be allocated to a Uniswap pool (pool of dreams).</p>
                </div>
                </Parallax>
                <Parallax speed={30} style={{ height: "100%" }} >
                <div className="single">
                    <h6>Educational Journey:</h6>
                    <p>Join us in this exploration of value, belief, and community. With $NOTHING, we're not just witnessing the evolution of a token; we're learning about the very fabric that weaves value in our digital world.</p>
                </div>
                </Parallax>
            </div>
        </div>
      </section>
    </>
  )
}

export default Essence
