import React from 'react'
import "./magic.scss"
import { Parallax } from 'react-scroll-parallax'

const Magic = () => {
    return (
        <>
            <section className="magic">
                <img src="\assets\magicmoment.svg" alt="img" className='img-fluid magicmoment img-wrapper' />
                <img src="\assets\magicmoment-mbl.svg" alt="img" className='img-fluid magicmoment-mbl d-none' />
                <div className="custom-container">
                    <div className="row">
                        <div className="col-xl-6 col-12 p-0 m-auto">
                            <Parallax speed={-15} style={{ height: "100%" }} >
                                <div className="main-content">
                                    <img src="\assets\magictextshadow.svg" alt="img" className='img-fluid text-abs' />
                                    <h6> <br /> <span>The power of <br /> consensus</span></h6>
                                    <span className='line-draw'></span>
                                    <p>This project is a statement against the traditional mechanisms of centralized finance, where the power often resides with a select few. In the world of NOTHING, each participant has an equal opportunity in the journey of value creation. </p>
                                </div>
                            </Parallax>
                        </div>
                        <div className="col-xl-6 col-12 p-0">
                            {/* <div className="img-wrapper text-end">
                                <img src="\assets\magicmoment.svg" alt="img" className='img-fluid' />
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Magic
