import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordScreen from './PasswordScreen';
import Landing from './components/landing/Landing';

function App() {
  // const [password, setPassword] = useState('moshe123');
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const history = useHistory();

  // useEffect(() => {
  //   const storedAuthentication = localStorage.getItem('authenticated');
  //   if (storedAuthentication === 'true') {
  //     setIsAuthenticated(true);
  //   }
  // }, []);

  // const handleLogin = (enteredPassword) => {
  //   if (enteredPassword === password) {
  //     localStorage.setItem('authenticated', 'true');
  //     setIsAuthenticated(true);
  //     history.push('/');
  //   } else {
      
  //     toast.error('Incorrect password. Please try again.');
  //   }
  // };

  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        <Route
          exact
          path='/'
          render={() => (<Landing />)}
        />
      </Router>
    </>
  );
}

export default App;
