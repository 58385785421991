import React from 'react'
import "./impact.scss"
import { Parallax } from 'react-scroll-parallax'

const Impact = () => {
    return (
        <>
            <section className="impact">
                <img src="\assets\transformativeimpact.svg" alt="img" className='img-fluid transformativeimpact img-wrapper' />
                <img src="\assets\transformativeimpact-mbl.svg" alt="img" className='img-fluid transformativeimpact-mbl d-none' />
                <div className="custom-container">
                    <div className="row">
                        <div className="col-xl-6 col-12 p-0">
                            {/* <div className="img-wrapper">
                                <img src="\assets\transformativeimpact.svg" alt="img" className='img-fluid' />
                            </div> */}
                        </div>
                        <div className="col-xl-6 col-12 p-0 m-auto">
                            <Parallax style={{ height: "100%" }} >
                                <div className="main-content">
                                    <img src="\assets\impactshadow.svg" alt="img" className='img-fluid text-abs' />
                                    <h6> <br /> <span>Transformative <br /> Impact:</span></h6>
                                    <span className='line-draw'></span>
                                    <p>For every token holder, this transformation is immediate and impactful. The moment $NOTHING turns into $SOMETHING, every piece of $NOTHING in your digital wallet becomes $SOMETHING - a reward for your faith and support.</p>
                                </div>
                            </Parallax>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Impact
