import React, { useEffect, useState } from 'react'
import './consensus.scss'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

const Consensus = () => {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (linkIndex) => {
    setActiveLink((prevActiveLink) => (prevActiveLink === linkIndex ? null : linkIndex));
  };

  const handleBodyClick = (event) => {

    if (!event.target.closest('a')) {
      setActiveLink(null);
    }
  };

  useEffect(() => {

    document.body.addEventListener('click', handleBodyClick);


    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);
  return (
    <section className="bgstyling">
      <ParallaxBanner
        layers={[{ image: '/assets/collective-bg.svg', speed: -20 }]}
        className="aspect-[2/1] set-custom-parallax"
        style={{ height: "100vh" }}
      />
      <Parallax scale={[0.75, 1]} style={{ height: "100%" }} >
        <div className="sectioninnerstyling">
          <img src="\assets\consensus.svg" alt="sectionimg" className="sectionimg" />
          <h4 className="sectionhead">
            Consensus is <span className='boldest'>the king</span>
          </h4>
          <div className="set-custom-parent">
            <a href='https://bit.ly/3RsGOup' target='_blank' className="whitebtn">BUY ON UNISWAP</a>
            {activeLink == 0 && <div className='nothing-text-banner1 formobilesetpositionbanner'>The Uniswap pool, AKA Pool of Dreams <br /> IS COMING VERY SOON...</div>}
          </div>
        </div>
      </Parallax>
    </section>
  )
}

export default Consensus