import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
const Footer = () => {
    // const [activeLink, setActiveLink] = useState(null);

    // const handleLinkClick = (linkIndex) => {
    //   setActiveLink((prevActiveLink) => (prevActiveLink === linkIndex ? null : linkIndex));
    // };


    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //       setActiveLink(null);
    //     }, 3000);
    
    //     return () => clearTimeout(timeoutId);
    //   }, [activeLink]);

    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (linkIndex) => {
      setActiveLink((prevActiveLink) => (prevActiveLink === linkIndex ? null : linkIndex));
    };
  
    const handleBodyClick = (event) => {

      if (!event.target.closest('a')) {
        setActiveLink(null);
      }
    };
  
    useEffect(() => {

      document.body.addEventListener('click', handleBodyClick);
  

      return () => {
        document.body.removeEventListener('click', handleBodyClick);
      };
    }, []);

 
    return (
        <>
            <section className="main-footer">
                <div className="custom-container">
                    <div className="footermain">
                        <div className="footerleft">
                            <img src="\logo.svg" alt="footerlogo" className="footerlogo" />
                            <div className="footerlinks">
                                <div className='set-parent'>
                                <a href='https://twitter.com/NOTHING31102008' className='footlink' target='_blank' >x (twitter)</a>
                                </div>
                                <div className='set-parent'>
                                <a href=' https://youtube.com/@Nothing-to-something?feature=shared' className='footlink' target='_blank'>YouTube</a>
                                {/* {activeLink == 1 && <div className='nothing-text'>NOTHING</div>} */}
                                </div> <div className='set-parent'>
                                {/* {activeLink == 2 && <div className='nothing-text formobilesetposition'>NOTHING</div>} */}
                                <a href='https://instagram.com/nothing31102008?igshid=OGQ5ZDc2ODk2ZA==' className='footlink' target='_blank' >Instagram</a>
                                </div>
                                <div className='set-parent'>
                                {/* {activeLink == 2 && <div className='nothing-text formobilesetposition'>NOTHING</div>} */}
                                <a href='https://t.me/nothingcash' className='footlink' target='_blank' >Telegram</a>
                                </div>
                                
                            </div>
                        </div>
                        <p className="footerrightpara">2023 Nothing token. no rights reserved.</p>
                    </div>
                </div>
            </section>
            <div className="footertext">
                <p className="footertextpara">BUILT BY NO ONE, POWERED BY EVERYONE</p>
            </div>
        </>
    )
}

export default Footer;
