import React from 'react'
import "./story.scss"
import { Parallax, useParallax } from 'react-scroll-parallax'

const Story = () => {
    return (
        <>
            <section className="story">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-xl-6 col-12 p-0 m-auto">
                            <Parallax speed={15} style={{ height: "100%" }} >
                                <div className="main-content">
                                    <img src="\assets\storyshadow.svg" alt="img" className='img-fluid text-abs' />
                                    <h6> <br /> <span>A Supply with a <br /> Story:</span></h6>
                                    <span className='line-draw'></span>
                                    <p>A TOTAL SUPPLY OF 311,020,080 TOKENS, SYMBOLIZING THE RELEASE DATE OF BITCOIN WHITEPAPER (31-10-2008), WHICH INSPIRED MILLIONS OF PEOPLE TO COLLECTIVELY CREATE VALUE USING CONSENSUS.</p>
                                    <p style={{ marginTop: "15px" }}>The Uniswap pool, AKA Pool of Dreams, will include 100% of the token on one side and a mere $25,000 on the other side.</p>
                                    <p style={{ marginTop: "15px" }}>iNITIAL MARKETCAP: $25,000</p>
                                    <p style={{ marginTop: "15px" }}>THE $25,000 WERE RECEIVED AS A DONATION</p>
                                </div>
                            </Parallax>
                        </div>
                        <div className="col-xl-6 col-12 p-0">
                            <div className="img-wrapper text-end">
                                <img src="\assets\supply.svg" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Story
