import React from 'react'
import './belief.scss'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'

const Belief = () => {
    return (
        <section className="bgstyling">
            <ParallaxBanner
                layers={[{ image: '/assets/collective-bg.svg', speed: -20 }]}
                className="aspect-[2/1] set-custom-parallax"
                style={{ height: "100vh" }}
            />
            <Parallax scale={[0.75, 1]} style={{ height: "100%" }} >
                <div className="sectioninnerstyling">
                    <img src="\assets\shapes.svg" alt="sectionimg" className="sectionimg" />
                    <h4 className="sectionhead">
                        Belief shapes <span className='boldest'>reality</span>
                    </h4>
                    <a href="\whitepaper.pdf" target="_blank" className="greybtn">READ WHITEPAPER</a>
                </div>
            </Parallax>
        </section>
    )
}

export default Belief